<template>
  <div id="app" style="min-width: 1530px; width: 100%">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style scoped lang="less">
// .quanping {
//   position: fixed;
//   right: 0;
//   top: 0;
//   font-size: 30px;
//   color: red;
//   z-index: 99;
// }
</style>
